import { ChangeEvent, useState, useEffect, KeyboardEvent } from 'react'
import { createUseStyles } from 'react-jss'
import { Colors } from '../constants/colors'
import useTimer from "../hooks/useTimer"
import S2 from './typography/S2'
import { cardPadding } from '../constants/layout'

const MIN_SEARCH_TERM_LENGTH = 2

export interface SearchResultItem {
  name: string
  value: string
}

interface Props {
  id: string
  value: string
  label?: string
  placeholder: string
  onSearchTermChange?: (searchTerm: string) => void
  searchResult: SearchResultItem[]
  onSelect?: (selectedItem: SearchResultItem | null) => void
}

const SearchAndSelectField = ({
  id = '',
  value = '',
  label,
  placeholder = '',
  onSearchTermChange = () => '',
  searchResult = [],
  onSelect = () => ''
}: Props) => {
  const styles = useStyles()
  const [searchTerm, setSearchTerm] = useState(value)
  const [dirty, setDirty] = useState(false)
  const [result, setResult] = useState<SearchResultItem[]>()
  const [selectedIndex, setSelectedIndex] = useState<number>()
  const { isRunning: isTimerRunning, restartTimer } = useTimer(400)

  useEffect(() => {
    if (value != searchTerm) {
      setSearchTerm(value)
    }           
  }, [value])

  useEffect(() => {
    setResult([...searchResult])

    if (searchResult?.length > 0) {
      setSelectedIndex(0)
    } else {
      setSelectedIndex(undefined)  
    }
  }, [searchResult])

  useEffect(() => {
    if (!isTimerRunning && searchTerm?.length >= MIN_SEARCH_TERM_LENGTH) {
      onSearchTermChange(searchTerm)
    }           
  }, [isTimerRunning])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value != searchTerm) {
      setSearchTerm(event.target.value)
      restartTimer()
    }

    setDirty(true)
  }

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (result && selectedIndex != null) {
        onSelect && onSelect(result[selectedIndex])
        setSearchTerm(result[selectedIndex].name)
        setResult(undefined)
      } else if (!searchTerm || searchTerm.length == 0) {
        onSelect && onSelect(null) 
      }
    }

    if (event.key === 'ArrowUp' && result && selectedIndex != null && selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1)
    }
    
    if (event.key === 'ArrowDown' && result && selectedIndex != null && selectedIndex < result.length - 1) {
      setSelectedIndex(selectedIndex + 1)
    }
  }

  const handleItemClick = (index: number) => {
    if (result && index < result.length - 1 && index >= 0)
    {
      onSelect && onSelect(result[index])
      setSearchTerm(result[index].name)
      setResult(undefined) 
    }   
  }

  const handleBlur = () => {
    setResult(undefined)  
  }

  const renderResultItems = () => {
    return result?.map((item, index) => {
      return (
        <div className={`${styles.resultItem} ${index == selectedIndex ? styles.selected : ''}`} key={item.value} id={item.value} onMouseDown={() => handleItemClick(index)}>
          {item.name}
        </div>)
    })
    
  }

  return (
    <>
    {label && <S2>{label}</S2>}
    <div className={styles.container} onBlur={handleBlur}>
      <input
        id={id}
        key={id}
        value={searchTerm}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        autoComplete='off'
        className={styles.input}
        placeholder={placeholder}
      />
      {dirty && result && searchTerm?.length >= MIN_SEARCH_TERM_LENGTH &&
      <div className={styles.resultContainer}>
        {renderResultItems()}
      </div>
      }
    </div>
    </>
  )
}

export default SearchAndSelectField

const useStyles = createUseStyles({
  container: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '5px',
    borderRadius: 5,
    backgroundColor: Colors.darkGray2,
    transition: '0.4s',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  },
  resultContainer: {
    position: 'absolute',
    top: '47px',
    zIndex: 100,
    width: '100%',
    borderColor: Colors.darkGrayBorder, 
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 5,
    backgroundColor: Colors.darkGray5,
    fontFamily: 'Aestetico-Regular',
    padding: '5px',
    '& div': {
      margin: '5px'
    }
  },
  resultItem: {
   padding: '5px', 
   color: Colors.darkGray2
  },
  selected: {
    backgroundColor: Colors.darkGrayBackground,
    borderRadius: 5
  },
  input: {
    width: '100%',
    border: 'none',
    borderRadius: 5,
    backgroundColor: 'transparent',
    fontFamily: 'Aestetico-Regular',
    fontSize: 16,
    padding: {
      top: cardPadding * 0.5,
      bottom: cardPadding * 0.5,
      right: cardPadding * 0.5,
      left: cardPadding * 0.5,
    },
    '&:focus': {
      border: 'none',
      backgroundColor: Colors.darkGray3,
      outline: 'none',
    },
    '&:active': {
      backgroundColor: Colors.darkGray3,
    },
  },
})
