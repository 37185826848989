
export interface PaginationData {
  pageIndex: number
  pageSize: number
  totalItemCount: number
  hasPreviousPage: boolean
  hasNextPage: boolean
}

export enum SalaryInterval {
  monthly = 'monthly',
  biweekly = 'biweekly'
}

export enum CountryCode {
  se = 'se',
  no = 'no'
}

export enum Language {
  sv = 'sv',
  en = 'en'
}

export interface Money {
  amount: number,
  currencyCode: string
}

export type IntegrationApiType = 'EmployerApi' | 'InvoicePayoutApi'

export interface Payday {
  id: string
  paymentDate: string
  completed: boolean
  locked: boolean
}

export enum CurrencyCodeString {
  sek = 'SEK',
  nok = 'NOK',
  dkk = 'DKK',
  eur = 'EUR'
}

export interface PayoutSettings {
  minAmountPerPayout?: number
  maxAmountPerPayout?: number
  maxAmountPerPayday?: number
  maxPercentPerPayday?: number
  enableCustomShiftSettings?: boolean
  maxCustomPercentPerPayday?: number
  maxNumberOfPayoutsPerPayday?: number
  currency?: CurrencyCodeString
  fee?: number
}

export type PayoutMethodType = 'SwishPayout' | 'BankTransfer'
export interface PayoutMethod {
  isDefault: boolean,
  payoutMethodType: PayoutMethodType,
  target: string
}

export interface PaydayListItemModel {
  id: string
  offCycle: boolean
  paymentDate: string
  employerId: string
  employerName: string
  hasValidationErrors: boolean
  payrollFileSubmitted: boolean
  completed: boolean
  highlighted: boolean
}

export interface PaydayModel {
  id: string
  offCycle: boolean
  paymentDate: string
  employerId: string
  employerName: string
  completed: boolean
  completedAt: string
  payrollDetails?: PayrollDetails
  employerPayRunDetails?: EmployerPayRunDetails
  employerDeductionDetails?: EmployerDeductionDetails
}
export type ValidationErrorType = 'UnknownEmployeeInPayroll' | 'EmployeeMissingInPayroll' | 'EmployeeWithNegativeBalance' | 'NonActivatedEmployeeInPayroll' | 'EmployeeWithIncorrectBankAccount'
export interface PayrollValidationError {
  parameters: string
  error: ValidationErrorType
}
export interface PayrollFile {
  externalId: string
  submittedDate: string
}
export interface PayrollDetails {
  paymentDate: string
  validationErrors: PayrollValidationError[]
  payrollFiles: PayrollFile[]
}
export type MoneyTransferState = 'Pending' | 'BusinessTransactionCommitPending' | 'Completed'
export type MoneyTransferType = 'DebtTransfer' | 'SalaryTransfer'
export interface BankTransferDetails {
  amount: Money
  state: MoneyTransferState
  type: MoneyTransferType
}
export type PayRunType = 'Default' | 'RemainingDebt'
export type PayRunState = 'Created' | 'ConnectedToBankAccountTransaction' | 'EmployerSalaryBusinessTransactionCommitPending' | 'EmployerSalaryBusinessTransactionCommitted' | 'TransferPending' | 'Completed'
export type DebtTransferState = 'None' | 'Pending' | 'Completed'
export type EmployerDeductionState = 'None' | 'Completed'

export enum PayTransferState {
  None = 'none',
  TransferPending = 'transferPending',
  TransferCompleted = 'transferCompleted',
  PayoutPending = 'payoutPending',
  Completed = 'completed',
  Paused = 'paused'
}

export enum PayRunPayoutAttemptState {
  None = 'none',
  Scheduled = 'scheduled',
  Pending = 'pending',
  Completed = 'completed',
  Failed = 'failed',
  FailedDueToInvalidSwishNumber = 'failedDueToInvalidSwishNumber'
}

export interface PayRunPayoutAttempt {
  initiatedAt: string
  scheduledAt: string | null
  completedAt: string | null
  state: PayRunPayoutAttemptState
  payoutMethod: PayoutMethod
  errorMessage: string | null
}
export interface PayRunModel {
  id: string
  created: string
  payDayId: string
  userId: string
  userGivenName: string
  userFamilyName: string
  userIdentificationNumber: string
  employerId: string
  employerName: string
  employeeId: string
  payoutMethod: PayoutMethod
  originalSalary: Money
  deductedPayoutAmount: Money
  deductedFeeAmount: Money
  salaryAfterDeductions: Money
  deductedPayoutAmountByEmployer: Money
  type: PayRunType
  state: PayRunState
  payTransferState: PayTransferState
  debtTransferState: DebtTransferState
  employerDeductionState: EmployerDeductionState
  partial: boolean
  frozenDueToPendingPayouts: boolean
  frozenDueToSalaryDeficit: boolean
  frozenDueToMissingInPayrollFile: boolean
  payoutAttempts: PayRunPayoutAttempt[]
}
export interface PayRunListItemModel {
  id: string
  userGivenName: string
  userFamilyName: string
  originalSalary: Money
  salaryAfterDeductions: Money
  deductedAmountByEmployer: Money
  type: PayRunType
  state: PayRunState
  frozen: boolean
}

export interface EmployerPayRunDetails {
  bankTransfers: BankTransferDetails[]
}

export interface EmployerDeductionDetails {
  amount: Money
}

export interface EmployeeTaxSettingsModel {
  taxTableIdentifier?: string
  fixedTaxRatePercent?: number
  payoutTaxInPercent?: number
}

export type EmployeeStatus = 'registered' | 'connected' | 'active' | 'paused' | 'terminated'
export enum EmployeeType {
  worker = 'worker',
  salaried = 'salaried'
}
export enum PayCalculationType {
  default = 'default',
  shiftBasedSalary = 'shiftBasedSalary'
}
export interface EmployeeListItemModel {
  id?: string
  externalId: string
  givenName?: string
  familyName?: string
  identificationNumber?: string,
  status: EmployeeStatus
}

export interface ConnectedUserModel {
  id: string
  givenName: string
  familyName: string
  connectedDate: string
}

export interface EmployeeModel {
  id?: string
  type: EmployeeType
  payCalculationType: PayCalculationType
  externalId?: string
  givenName?: string
  familyName?: string
  identificationNumber?: string
  bankAccountNumber?: string
  email?: string
  phoneNumber?: string
  department?: string
  category?: string
  location?: string
  costCenter?: string
  payrollFileIdentifier?: string
  salaryEarningOnWeekends?: boolean
  activated?: boolean
  startDate?: string
  initialStartDate?: string
  activationDate?: string
  terminationDate?: string
  paused?: boolean
  pauseReason?: string
  pauseDate?: string
  nonEligible: boolean
  underForeclosure: boolean
  pendingBankAccountActivation: boolean
  bankAccountActivationFailed: boolean
  taxSettings: EmployeeTaxSettingsModel
  status: EmployeeStatus
  payoutSettings: PayoutSettings
  effectivePayoutSettings: PayoutSettings
  connectedUser?: ConnectedUserModel
  employerId: string
  employerName: string
  grossSalary: Money
  grossHourlyWage: Money
  handleVacationPay: boolean
  vacationPayPercent: number | null
  lastPayDataUpdate: string
  isEligibleForActivationReminder: boolean
  welcomeMessageSent: boolean
  lastActivationReminderDate?: string
  nextActivationReminderDate?: string
}

export interface EmployerBankAccount {
  id: string
  bankAccountNumber: string
}

export type PortalRole = 'Owner' | 'Admin' | 'PayrollAdmin' | 'ActivationAdmin' | 'FinanceAdmin' | 'TechnicalContact' | 'PayrollContact' | 'FinanceContact'
export interface PortalUserModel {
  portalUserId: string | null
  employerId: string
  givenName: string
  familyName: string
  email: string
  phoneNumber: string
  language: Language
  roles: PortalRole[]
}

export interface PortalUserEditModel {
  portalUserId: string | null
  email: string
  roles: PortalRole[]
}

export enum Periodicity {
  monthly = 'monthly'
}

export enum AccrualType {
  arrears = 'arrears',
  advance = 'advance'
}

export enum PaydayOnWeekendHandling {
  advance = 'advance',
  postpone = 'postpone'
}

export interface PayScheduleEditModel {
  id: string | null
  identifier: string
  periodicity: Periodicity
  salaryAccrualType: AccrualType
  salaryEarningOnWeekends: boolean
  periodStartDayOfMonth: number
  deviationPeriodEndOffset: number
  defaultPaymentDayOfMonth: number
  paydayOnWeekendHandling: PaydayOnWeekendHandling
}

export interface PayScheduleListItem extends PayScheduleEditModel {
  isDefault: boolean
}

export interface PaySchedulePaydayModel {
  id: string
  paymentDate: string
  isShared: boolean
  isReadOnly: boolean
  payoutsDisabled: boolean
  payrollExists: boolean
}

export interface PayScheduleModel extends PayScheduleListItem {
  paydays: PaySchedulePaydayModel[]
}

export interface CreditorModel {
  id: string
  name: string
}

export interface EmployerNameModel {
  id: string
  name: string
}

export interface CustomerListEmployerInfoModel {
  id: string,
  state: EmployerState
}
export interface CustomerListInvoicePayoutProviderInfoModel {
  id: string,
  state: InvoicePayoutProviderState
}
export interface CustomerListItemModel {
  id: string
  marketId: string
  companyRegistrationNumber?: string
  name?: string
  legalName?: string
  country: CountryCode
  state: CustomerState
  employer: CustomerListEmployerInfoModel | null,
  invoicePayoutProvider: CustomerListInvoicePayoutProviderInfoModel | null
}
export interface EmployerListItemModel {
  id: string
  companyRegistrationNumber?: string
  name?: string
  state: CustomerState
}
export interface InvoicePayoutProviderListItemModel {
  id: string
  companyRegistrationNumber?: string
  name?: string
  state: CustomerState
}
export interface InvoicePayoutListItemModel {
  id: string
  createDate: string
  invoiceNumber: string
  amountBeforeFee: Money
  amountAfterFee: Money
  recipientName: string
  recipientOrganizationNumber: string
  state: InvoicePayoutState
}
export type CustomerState = 'active' | 'locked' | 'terminated'
export type EmployerState = 'active' | 'locked' | 'terminated'
export type InvoicePayoutProviderState = 'active' | 'locked' | 'terminated'
export type InvoicePayoutState = 'created' | 'remoteValidationPending' | 'creditAllocationPending' | 'transferPending' | 'completed' | 'remoteValidationFailed' | 'transferFailed' | 'cancelled'
export enum EmployeeActivationType {
  bankAccount,
  manual,
  automatic
}
export enum EmployeeDeactivationType {
  manual,
  automatic
}
export enum PayrollSystem {
  unknown,
  caspeco,
  personalkollen
}
export enum PayrollFileFormat {
  none,
  bankgiro,
  hogia,
  fortnoxPdf,
  sus,
  painV03
}
export enum PayrollSystemImportFileFormat {
  paXml,
  tlu,
  hogia
}
export enum EmployeeDataParserType {
  csv,
  hogia,
  fram,
  visma600UpdateOnly,
  visma600CreateAndUpdate
}
export enum AdminFlowType {
  basisImport,
  agiAdjustment
}
export enum ShiftDataParserType {
  csv,
  fram
}
export enum CalendarMonthPayoutBasisReportGenerationType {
  automatic,
  manual
}
export enum PayrollFileEmployeeIdentifierType {
  id10,
  externalId,
  custom,
  id12
}
export interface CreditorConnectionModel {
  id: string
  name: string
  priority: number
}
export interface CompanyReportModel {
  created: string,
  rawReport: string
}

export interface CustomerModel {
  id: string
  companyRegistrationNumber?: string
  name?: string
  legalName?: string
  agreementNumber?: string
  marketId: string
  marketName: string
  language: Language
  state: CustomerState
  timeZoneId: string
  employer: CustomerListEmployerInfoModel | null,
  invoicePayoutProvider: CustomerListInvoicePayoutProviderInfoModel | null
  invoiceEmailAddress: string | null
}
export interface InvoicePayoutProviderModel {
  id: string
  customerId: string
  customerName: string
  marketId: string
  state: InvoicePayoutProviderState
  currency: CurrencyCodeString
  launchDate: string
  creditLimit: Money
  creditUsage: Money
  remainingCreditSpaceWarningPercent: number,
  creditSpaceWarningSilencedUntilDate: string,
  creditors: CreditorConnectionModel[]
  callbackUrl: string | null
  requestValidationUrl: string
  signingCertificateThumbprint: string
  cappyFeePercentage: number
  customerFeePercentage: number
  minimumPayoutAmount: number
  maximumPayoutAmount: number
  minimumDaysUntilDueDate: number
}
export interface InvoicePayoutRecipient {
  identificationNumber: string
  name: string
  street: string
  postalCode: string
  city: string
  country: string
}
export type InvoicePayoutRepaymentState = 'created' | 'paid' | 'settled'
export interface InvoicePayoutRepayment {
  id: string
  dueDate: string
  state: InvoicePayoutRepaymentState
  reference: string
}
export type BankTransferType = 'domesticBbanTransfer' | 'crossBorderIbanTransfer' | 'domesticBankgiroTransfer' | 'domesticPlusgiroTransfer'
export interface InvoicePayoutBankTransfer {
  type: BankTransferType
  bankAccount: string
  bankCode: string
  bic: string
  ocrNumber: string
  regulatoryPurposeCode: string
  recipientIdentificationNumber: string
  recipientName: string
  recipientStreet: string
  recipientPostalCode: string
  recipientCity: string
  recipientCountry: string
}
export interface InvoicePayoutBasis {
  repayment: InvoicePayoutRepayment
  recipient: InvoicePayoutRecipient
  bankTransfer: InvoicePayoutBankTransfer
  invoiceNumber: string
  invoiceReference: string
  dueDateString: string
}
export interface InvoicePayoutModel {
  id: string
  state: InvoicePayoutState
  createDate: string
  completedDate: string
  invoicePayoutProviderId: string
  amountBeforeFee: Money
  amountAfterFee: Money
  cappyFee: Money
  providerFee: Money
  basis: InvoicePayoutBasis
}

export interface EmployerModel {
  id: string
  customerId: string
  customerName: string
  marketId: string
  appAbbreviation?: string
  payoutSettings: PayoutSettings
  effectivePayoutSettings: PayoutSettings
  portalUsers: PortalUserModel[]
  state: EmployerState
  bankAccount?: EmployerBankAccount
  launchDate: string
  payrollSystem: PayrollSystem
  payrollFileFormat: PayrollFileFormat
  enablePayrollFileAdjustment: boolean
  payrollSystemImportFileFormat: PayrollSystemImportFileFormat
  employeeDataParserType: EmployeeDataParserType
  shiftDataParserType: ShiftDataParserType
  payrollFileEmployeeIdentifierType: PayrollFileEmployeeIdentifierType
  calendarMonthPayoutBasisReportGenerationType: CalendarMonthPayoutBasisReportGenerationType
  enablePayrollSystemBankAccountImportFile: boolean
  payrollSystemBankAccountImportFileCustomField: string
  employeeActivationType: EmployeeActivationType
  employeeDeactivationType: EmployeeDeactivationType
  activationNotificationDaysOfWeek: string[]
  creditLimit: Money
  creditUsage: Money
  remainingCreditSpaceWarningPercent: number,
  creditSpaceWarningSilencedUntilDate: string,
  lastShiftOrSalaryUpdate: string
  disablePaydayDeductionFileGeneration: boolean
  wagesPayrollCodeForPaydayDeductions: string
  salaryPayrollCodeForPaydayDeductions: string
  wagesPayrollCodeForCalendarMonthPayoutBasis: string
  salaryPayrollCodeForCalendarMonthPayoutBasis: string
  vacationPayPayrollCode: string
  defaultVacationPayPercent: number | null
  defaultPayoutTaxInPercent: number
  defaultTaxTableIdentifier: string
  defaultFixedTaxRatePercent: number | null
  payoutTaxImmutable: boolean
  employeeActivationFreezeDaysBeforePayday: number
  calenderMonthPayoutBasisReportGenerationDayOfMonth: number | null
  paySchedules: PayScheduleListItem[]
  creditors: CreditorConnectionModel[]
  newEmployeeMessageEnabled: boolean
  employeeActivationReminderEnabled: boolean
  employeeActivationReminderIntervalDays: number
  plannedShiftsEnabled: boolean
  newEmployeesReportEnabled: boolean
  adminFlowType: AdminFlowType
  bookkeepingConfiguration?: BookkeepingConfigurationModel
  employerLogos: EmployerLogoModel[]
  freePayoutCountOnEmployeeActivation: number
  payrollFileExecutionDateOneDayBeforePayment: boolean
  employerInitiatedPayoutsEnabled: boolean
  employerInitiatedPayoutTaxPercent: number
  employerInitiatedPayoutClientConfiguration: string
  allowShiftsAndDeviationsOutsideCurrentEmploymentBounds: boolean
  manualPayrollFileRowCreationEnabled: boolean
  employeeMinAgeInYears: number
}

export interface EmployerLogoModel
{
  theme: string,
  contentType: string,
  image: string
}


export interface CreateFreePayoutModel {
  numberOfFreePayouts: number
}

export interface CreateNewCustomerModel {
  companyRegistrationNumber: string
  name: string
  legalName: string
  marketId: string
  language: Language,
}
export interface CreateNewEmployerModel {
  customerId: string
}
export interface CreateNewInvoicePayoutProviderModel {
  customerId: string
}

export interface UpdateCustomerModel {
  companyRegistrationNumber?: string
  name?: string
  legalName?: string
  agreementNumber?: string
  language: Language
  timeZoneId: string
  invoiceEmailAddress: string | null
}
export interface UpdateEmployerModel {
  appAbbreviation?: string
  creditLimit: number
  remainingCreditSpaceWarningPercent: number,
  employeeActivationType: EmployeeActivationType
  employeeDeactivationType: EmployeeDeactivationType
  payrollSystem: PayrollSystem
  payrollFileFormat: PayrollFileFormat
  enablePayrollFileAdjustment: boolean
  payrollSystemImportFileFormat: PayrollSystemImportFileFormat
  employeeDataParserType: EmployeeDataParserType
  shiftDataParserType: ShiftDataParserType
  payrollFileEmployeeIdentifierType: PayrollFileEmployeeIdentifierType
  calendarMonthPayoutBasisReportGenerationType: CalendarMonthPayoutBasisReportGenerationType
  enablePayrollSystemBankAccountImportFile: boolean
  payrollSystemBankAccountImportFileCustomField: string
  disablePaydayDeductionFileGeneration: boolean
  wagesPayrollCodeForPaydayDeductions: string
  salaryPayrollCodeForPaydayDeductions: string
  wagesPayrollCodeForCalendarMonthPayoutBasis: string
  salaryPayrollCodeForCalendarMonthPayoutBasis: string
  vacationPayPayrollCode: string
  defaultVacationPayPercent: number | null
  defaultPayoutTaxInPercent: number
  defaultTaxTableIdentifier: string
  defaultFixedTaxRatePercent: number | null
  payoutTaxImmutable: boolean
  employeeActivationFreezeDaysBeforePayday: number
  calenderMonthPayoutBasisReportGenerationDayOfMonth: number | null
  launchDate: string
  newEmployeeMessageEnabled: boolean
  employeeActivationReminderEnabled: boolean
  employeeActivationReminderIntervalDays: number
  plannedShiftsEnabled: boolean
  newEmployeesReportEnabled: boolean
  adminFlowType: AdminFlowType
  freePayoutCountOnEmployeeActivation: number
  payrollFileExecutionDateOneDayBeforePayment: boolean
  employerInitiatedPayoutsEnabled: boolean
  employerInitiatedPayoutTaxPercent: number
  employerInitiatedPayoutClientConfiguration: string
  allowShiftsAndDeviationsOutsideCurrentEmploymentBounds: boolean
  manualPayrollFileRowCreationEnabled: boolean
  employeeMinAgeInYears: number
}
export interface UpdateInvoicePayoutProviderModel {
  creditLimit: number
  remainingCreditSpaceWarningPercent: number,
  launchDate: string
  currency: CurrencyCodeString
  callbackUrl: string | null
  requestValidationUrl: string
  signingCertificateThumbprint: string
  cappyFeePercentage: number
  customerFeePercentage: number
  minimumPayoutAmount: number
  maximumPayoutAmount: number
  minimumDaysUntilDueDate: number
}

export interface ConnectedEmployeeModel {
  id: string
  externalId: string
  activationDate: string
  connectedToUserDate: string
  status: EmployeeStatus
  employerId: string
  employerName: string
}

export interface PreliminaryPayslipData {
  grossPay: Money
  grossAdvances: Money
  netAdvances: Money
  tax: Money
  fees: Money
  payAfterDeductions: Money
}

export interface PaydayInfo {
  id: string
  paymentDate: string
  completed: boolean
  locked: boolean
  frozenByActivation: boolean
  frozenByEarlyPayoutBasisGeneration: boolean
  wagesPayPeriodStartDate: boolean
  wagesPayPeriodEndDate: string
  salaryPayPeriodStartDate: string
  salaryPayPeriodEndDate: string
}

export interface IncomeInfo {
  grossTotalAmount: Money
  netTotalAmount: Money
}

export interface ShiftsInfo {
  aggregatedGrossWages: Money
  aggregatedNetWages: Money
  aggregatedHours: number
  shiftCount: number
  shifts: ShiftInfo[]
}

export enum ShiftType {
  standard,
  custom
}

export interface ShiftInfo {
  id: string
  date: string
  startTime: string | null
  endTime: string | null
  grossWages: Money
  netWages: Money
  hours: number
  location: string
  type: ShiftType
}

export interface SalaryInfo {
  earningRatio: number
  grossSalary: Money
  currentGrossAmount: Money
  currentNetAmount: Money
  containsSalary: boolean
}

export interface DeviationsInfo {
  aggregatedGrossAmount: Money
  aggregatedNetAmount: Money
  deviationCount: number
  deviations: DeviationInfo[]
}

export enum DeviationType {
  other,
  sickLeave,
  sickChildLeave
}

export interface DeviationInfo {
  id: string
  date: string
  grossAmount: Money
  netAmount: Money
  type: DeviationType
}

export interface PayoutHistoryInfo {
  aggregatedAmount: Money
  aggregatedAmountMonthBeforePayday: Money
  aggregatedFeeAmount: Money
  payoutCount: number
}

export interface PayoutAvailabilityInfo {
  availableAmount: Money
  reservedAmount: Money
  availablePayoutCount: number
}

export interface IncomeInfo {
  grossTotalAmount: Money
  netTotalAmount: Money
}

export interface PaydayDataModel {
  payCalculationType: string
  payday: PaydayInfo
  income: IncomeInfo
  completedShifts: ShiftsInfo
  plannedShifts: ShiftsInfo
  salary: SalaryInfo
  deviations: DeviationsInfo
  payoutHistory: PayoutHistoryInfo
  preliminaryPayslipData: PreliminaryPayslipData
  payoutAvailability: PayoutAvailabilityInfo
  lastDataUpdate: Date
}

export interface EmployerPaydayDataModel {
  employerId: string
  employerName: string
  payoutSettings: PayoutSettings
  paydays: PaydayDataModel[]
}

export interface UserFreePayouts {
  available: number
  used: number
}

export interface UserPayoutCooldownPeriodModel
{
  start: string
  end: string
  state: string
  isActive: boolean
}

export interface UserModel {
  id: string
  givenName: string
  familyName: string
  birthDate?: string
  identificationNumber?: string
  email: string
  mobilePhone: string
  unverifiedMobilePhone: string
  marketId: string
  marketName: string
  preferredLanguage: Language
  payoutSettings: PayoutSettings
  payoutMethods: PayoutMethod[]
  state: string
  accountDeletionDate: string
  accountDeletionRequestDate: string
  createdDate: string
  latestLoginDate?: string
  numberOfLogins: number
  appVersion: string
  activationGuideCompleted: boolean
  dataCollectionConsent: boolean
  connectedEmployees: ConnectedEmployeeModel[]
  employerPaydayData: EmployerPaydayDataModel[]
  freePayouts: UserFreePayouts
  mobilePhoneVerificationDisabled: boolean
  payoutCooldownPeriods: UserPayoutCooldownPeriodModel[]
}

export interface UserListItemModel {
  id: string
  givenName: string
  familyName: string
  identificationNumber?: string
  marketId: string
  state: string
}

export interface PagedListItems<T> {
  items: T[]
  totalCount: number
}

export enum TransactionStatus {
  Pending = 'pending',
  Cancelled = 'cancelled',
  Completed = 'completed'
}
export enum TransactionType {
  CreditorPrefundingTransaction = 'CreditorPrefundingTransaction',
  EmployeePayoutTransaction = 'EmployeePayoutTransaction',
  EmployerPayPaymentTransaction = 'EmployerPayPaymentTransaction',
  TransferDebtsToLandingAccountTransaction = 'TransferDebtsToLandingAccountTransaction',
  TransferPaysToOperatingAccountTransaction = 'TransferPaysToOperatingAccountTransaction',
  PayPayoutTransaction = 'PayPayoutTransaction',
  CreditorDebtSettlementTransaction = 'CreditorDebtSettlementTransaction',
  CreditorDebtRecycleTransaction = 'CreditorDebtRecycleTransaction',
  TransferFeesToTransactionAccountTransaction = 'TransferFeesToTransactionAccountTransaction',
  EmployerPayDeductionPaymentTransaction = 'EmployerPayDeductionPaymentTransaction',
  CreditorPostfundingTransaction = 'CreditorPostfundingTransaction',
  FundingForPostfundingCreditorsTransaction = 'FundingForPostfundingCreditorsTransaction',
  FundingForPostfundingCreditorsRepaymentTransaction = 'FundingForPostfundingCreditorsRepaymentTransaction',
  InvoicePayoutTransaction = 'InvoicePayoutTransaction',
  InvoicePayoutRepaymentTransaction = 'InvoicePayoutRepaymentTransaction',
}
export interface TransactionListItemModel {
  id: string
  date: string
  type: TransactionType
  state: TransactionStatus
  amount: Money
  employerId?: string
  invoicePayoutProviderId?: string
  customerName?: string
  userId?: string
  userGivenName?: string
  userFamilyName?: string
  paydayDate?: string
}

export interface EmployeePayoutTransactionAdditions {
  fee: Money
  payoutMethod: PayoutMethod
  repaymentDate: string
  employerId: string
  employerName: string
  userId: string
  userGivenName: string
  userFamilyName: string
  failureReason: string
}

export interface EmployerPayPaymentTransactionAdditions {
  deductedFeeAmount: Money
  deductedPayoutAmount: Money
  remainingPayAmount: Money
  employerId: string
  employerName: string
  userId: string
  userGivenName: string
  userFamilyName: string
}

export interface PayPayoutTransactionAdditions {
  payoutMethod: PayoutMethod
  employerId: string
  employerName: string
  userId: string
  userGivenName: string
  userFamilyName: string
}

export interface TransferDebtsToLandingAccountTransactionAdditions {
  employerId: string
  employerName: string
}

export interface TransferPaysToOperatingAccountTransactionAdditions {
  employerId: string
  employerName: string
}

export interface EmployerPayDeductionPaymentTransactionAdditions {
  employerId: string
  employerName: string
}

export interface CreditorPostfundingTransactionAdditions {
  creditorId: string
  creditorName: string
  fundingCost: Money
}

export interface InvoicePayoutTransactionAdditions {
  invoicePayoutProviderId: string
  invoicePayoutId: string
}

export interface InvoicePayoutRepaymentTransactionAdditions {
  invoicePayoutProviderId: string
  invoicePayoutRepaymentId: string
}

export interface TransactionModel {
  id: string
  date: string
  type: TransactionType
  state: TransactionStatus
  amount: Money
  employeePayoutTransactionDetails?: EmployeePayoutTransactionAdditions
  employerPayPaymentTransactionDetails?: EmployerPayPaymentTransactionAdditions
  payPayoutTransactionDetails?: PayPayoutTransactionAdditions
  transferDebtsToLandingAccountTransactionDetails?: TransferDebtsToLandingAccountTransactionAdditions
  transferPaysToOperatingAccountTransactionDetails?: TransferPaysToOperatingAccountTransactionAdditions
  employerPayDeductionPaymentTransactionDetails?: EmployerPayDeductionPaymentTransactionAdditions
  creditorPostfundingTransactionDetails?: CreditorPostfundingTransactionAdditions
  invoicePayoutTransactionDetails: InvoicePayoutTransactionAdditions
  invoicePayoutRepaymentTransactionDetails: InvoicePayoutRepaymentTransactionAdditions
}

export interface ReconciliationListItemModel {
  id: string
  date: string
  hasUnhandledIssues: boolean
  totalIssueCount: number
}

export interface ReconciliationIssueModel {
  id: string
  reconciliationId: string
  details: string
  handled: boolean
  handledDate: string
  handledComment: string
}

export interface ReconciliationModel {
  id: string
  date: string
  hasUnhandledIssues: boolean
  issues: ReconciliationIssueModel[]
}

export interface BankAccountModel {
  marketId: string
  bankAccountId: string
  name: string
  bban: string
  connectedEmployers: EmployerConnectedToBankAccount[]
}

export interface EmployerConnectedToBankAccount {
  employerId: string
  employerName: string
}

export interface SalaryModel {
  id: string
  paymentDate: string
  grossSalary: Money
  startDate: string
  endDate: string
}

export interface ShiftModel {
  id: string
  paymentDate: string
  date: string
  type: string
  status: string
  hours: number
  grossWages: Money
}

export interface PayDeviationModel {
  id: string
  description: string
  paymentDate: string
  grossAmount: Money
}

export interface TokenModel {
  key: string
  description: string
  expires: string
}

export enum BankTransferState {
  PaymentVerificationRequired = 'paymentVerificationRequired',
  SecondarySigningRequired = 'secondarySigningRequired'
}
export interface FrozenBankTransfer {
  identifier: string
  amount: Money
  fromAccountBban: string
  toAccountBban: string
  state: BankTransferState
}

export interface MarketModel {
  id: string
  country: CountryCode
  payoutSettings: PayoutSettings
}

export interface TransactionFilterParams {
  userId: string | null
  userSearchTerm: string | null
  employerId: string | null
  employerSearchTerm: string | null
  invoicePayoutProviderId: string | null
  invoicePayoutProviderSearchTerm: string | null
  state: TransactionStatus | null
  transactionType: TransactionType | null
  fromDate: string | null
  toDate: string | null
  paydayDate: string | null
}

export interface EmployeeFilterParams {
  searchTerm: string | null
  employerId: string | null
}

export interface InvoicePayoutFilterParams {
  searchTerm: string | null
  invoicePayoutProviderId: string | null
}

export interface EmployeeAndPaydayFilterParams {
  employeeId: string
  paydayId: string | null
}

export interface PaydayFilterParams {
  employerName: string | null
  employerId: string | null
  includeCompletedPaydays: boolean
}

export interface PayRunFilterParams {
  paydayId: string
  userId: string | null
  userName: string | null
  includeCompletedPayRuns: boolean
}

export interface ProtectCustomerModel {
  id: string
  employerId: string
  invoicePayoutProviderId: string
  name: string
  issues: ProtectIssue[]
  rules: ProtectRule[]
}

export interface ProtectCustomerListItem {
  id: string
  name: string
}

export interface ProtectIssue {
  id: string
  ruleId: string
  ruleName: string
  isGlobalRule: boolean
  createdDate: string
  customerId: string
  customerName: string
  description: string
  investigationResult: string
  closed: boolean
  closedDate: string | null
}

export interface ProtectIssueFilterParams {
  includeHandled: boolean
}

export interface ProtectRuleListItem {
  id: string
  name: string
  type: string
  customerId: string
  customerName: string
  triggered: boolean
}

export enum ProtectRuleType {
  PayoutVolumeZScoreRule = 'PayoutVolumeZScoreRule',
  ActivationRateZScoreRule = 'ActivationRateZScoreRule',
  OverdrawsRule = 'OverdrawsRule',
  AbnormalEarningsRule = 'AbnormalEarningsRule',
  PayoutVolumeRateRule = 'PayoutVolumeRateRule',
  AveragePayoutAmountRule = 'AveragePayoutAmountRule',
  MaxSalaryRule = 'MaxSalaryRule',
  MaxInvoicePayoutAmountRule = 'MaxInvoicePayoutAmountRule',
  InvoicePayoutRateRule = 'InvoicePayoutRateRule',
  InvoicePayoutVolumeRateRule = 'InvoicePayoutVolumeRateRule',
  InvoicePayoutRateForRecipientRule = 'InvoicePayoutRateForRecipientRule',
  InvoicePayoutVolumeRateForRecipientRule = 'InvoicePayoutVolumeRateForRecipientRule',
  InvoicePayoutVolumeZScoreRule = 'InvoicePayoutVolumeZScoreRule'
}

export interface ProtectRule {
  id: string
  name: string
  type: ProtectRuleType
  hysteresis: string
  customerId: string
  customerName: string
  triggered: boolean
  deleted: boolean
  rawResult: string

  triggerValue?: number
  currentWindowSize?: string
  compareWindowSize?: string
  skipDays?: number
  withdrawalLimit?: number
  minSampleThreshold?: number
}

export interface CreateOrUpdateProtectRuleModel {
  id?: string
  name: string
  type: ProtectRuleType | null
  hysteresis: string
  customerId: string

  triggerValue?: number
  currentWindowSize?: string
  compareWindowSize?: string
  skipDays?: number
  withdrawalLimit?: number
  minSampleThreshold?: number
}

export interface UpdateProtectIssueModel {
  customerId?: string
  investigationResult?: string
  closed: boolean
}

export interface ProtectDailyZScoreResult {
  date: string
  value: number,
  zScore: number
}

export interface ProtectStandardDeviation {
  count: number,
  mean: number,
  variance: number,
  sigma: number,
  standardErrorMean: number
}

export interface ProtectZScoreResult {
  standardDeviation: ProtectStandardDeviation
  currentResult: ProtectDailyZScoreResult
  historicalResults: ProtectDailyZScoreResult[]
}

export interface ProtectUserAmount {
  amount: number
  userId: string
}

export interface ProtectRepaymentDateResult {
  description: string
  overdraws: ProtectUserAmount[]
  repaymentDate: string
}

export interface ProtectPayoutSizeResult {
  repaymentDateResults: ProtectRepaymentDateResult[]
}

export interface ProtectAbnormalEarnings {
  amount: Money
  employeeId: string
}

export interface ProtectAbnormalEarningsResult {
  abnormalEarnings: ProtectAbnormalEarnings[]
}

export interface ProtectPayoutVolumeRateResult {
  currentVolume: number
}

export interface ProtectAveragePayoutAmountResult {
  currentAverageAmount: number
}

export interface ProtectEmployeeWithSalary {
  employeeId: string
  grossSalary: Money
}

export interface ProtectMaxSalaryResult {
  salaries: ProtectEmployeeWithSalary[]
}

export interface ProtectInvoicePayoutWithAmount {
  invoicePayoutId: string
  amount: Money
}

export interface ProtectMaxInvoicePayoutAmountResult {
  invoicePayouts: ProtectInvoicePayoutWithAmount[]
}

export interface ProtectInvoicePayoutRateResult {
  currentCount: number
}

export interface ProtectInvoicePayoutVolumeRateResult {
  currentVolume: number
}

export interface ProtectInvoicePayoutRateForRecipient {
  recipientAccount: string
  count: number
}

export interface ProtectInvoicePayoutRateForRecipientResult {
  stats: ProtectInvoicePayoutRateForRecipient[]
}

export interface InvoicePayoutVolumeRateForRecipient {
  recipientAccount: string
  volume: number
}

export interface ProtectInvoicePayoutVolumeRateForRecipientResult {
  stats: InvoicePayoutVolumeRateForRecipient[]
}

export enum CampaignType {
  FreePayoutCampaign = 'FreePayoutCampaign'
}

export enum CampaignRecipientType {
  Individuals = 'individuals',
  ActiveEmployees = 'activeEmployees'
}

export enum CampaignState {
  New = 'new',
  Launched = 'launched',
  Ongoing = 'ongoing',
  Completed = 'completed',
  Aborted = 'aborted',
  Failed = 'failed'
}

export interface CampaignTranslation {
  language: Language
  title: string
  summary: string
}

export interface CreateOrUpdateCampaignModel {
  type: CampaignType
  name: string
  executionTime: string
  recipientType: CampaignRecipientType
  recipients: string[]
  numberOfFreePayouts: number
}

export interface CampaignRecipient {
  id: string
  givenName: string
  familyName: string
}

export interface Campaign {
  id: string
  type: CampaignType
  name: string
  executionTime: string
  state: CampaignState
  recipientType: CampaignRecipientType
  recipients: CampaignRecipient[]
  messages: CampaignTranslation[]
  numberOfFreePayouts: number
}

export interface DashboardMetrics {
  cappyKpiMetrics: CappyKpiMetrics
  activityKpiMetrics : ActivityKpiMetrics
}

export interface TemporalValue<T>
{
  value: T
  date: string
}

export interface NumericTimeSeries {
  values: TemporalValue<number>[]
  firstValue: number
  lastValue: number
  min: number
  max: number
  delta: number
  average: number
  deltaPercentage: number
  currentPercentage: number
}

export interface MonetaryTimeSeries {
  values: TemporalValue<Money>[]
  firstValue: Money
  lastValue: Money
  min: Money
  max: Money
  delta: Money
  average: Money
  deltaPercentage: number
}

export interface CappyKpiMetrics {
  activatedEmployeeCount: NumericTimeSeries
  terminatedEmployeeCount: NumericTimeSeries
  nonEligibleEmployeeCount: NumericTimeSeries
  activatedTerminatedEmployeeCount: NumericTimeSeries
  activatedNonTerminatedEmployeeCount: NumericTimeSeries
  employeeCount: NumericTimeSeries
  eligibleEmployeeCount: NumericTimeSeries
  userCount: NumericTimeSeries
  deletedUserCount: NumericTimeSeries
  payoutCountSek: NumericTimeSeries
  payoutSumSek: MonetaryTimeSeries
  payPaymentCountSek: NumericTimeSeries
  payPaymentSumSek: MonetaryTimeSeries
  employeeActivationConversion: NumericTimeSeries
  creditUsageSek: MonetaryTimeSeries
  outstandingFeesSek: MonetaryTimeSeries
}

export interface ActivityKpiMetrics {
  appLoginCount: NumericTimeSeries
  dailyActiveUsers: NumericTimeSeries
  monthlyActiveUsers: NumericTimeSeries
  dailyActiveUsersWithdrawalShare: NumericTimeSeries
  monthlyActiveUsersWithdrawalShare: NumericTimeSeries
  productStickiness: NumericTimeSeries
}

export interface InAppMessage {
  id: string
  employerId: string | undefined,
  employerName: string | undefined,
  internalName: string
  icon: string
  url: string | undefined
  validFrom: string
  validTo: string | undefined
  sticky: boolean
  minAppVersion: string
  priority: number
  published: boolean
  readCount: number
  translations: InAppMessageTranslation[]
}

export interface CreateOrUpdateInAppMessageModel {
  employerId: string | undefined,
  internalName: string
  icon: string
  url: string | undefined
  validFrom: string
  validTo: string | undefined
  sticky: boolean
  minAppVersion: string
  priority: number
}

export interface InAppMessageTranslation {
  type: string
  title: string
  description: string
  buttonText: string
  language: Language
}

export enum BookkeepingAccountType {
  salaryAccount = 'salaryAccount',
  taxAccount = 'taxAccount',
  cappyAdjustmentAccount = 'cappyAdjustmentAccount',
  socialFeeAccount = 'socialFeeAccount',
  socialFeeSettlementAccount = 'socialFeeSettlementAccount'
}

export interface BookkeepingConfigurationModel
{
  verificationDescription: string
  verificationsPerIndividual: boolean
  accountTypeConfigurations: BookkeepingAccountTypeConfigurationModel[]
}

export interface BookkeepingAccountTypeConfigurationModel
{
  accountType: BookkeepingAccountType
  includeCostCenterDimension: boolean
  includeEmploymentDimension: boolean
  includeProjectDimension: boolean
  rules: BookkeepingAccountTypeRuleModel[]
}

export interface BookkeepingAccountTypeRuleModel
{
  orderNumber: number
  account: number
  costCenter: string
  project: string
  filterEmployeeType: EmployeeType | undefined
  filterDepartment: string
  filterCategory: string
}

////////////////////////////
// Learning management types
////////////////////////////
export interface ImageModel {
  name: string
  uri: string
}

export interface MultilingualString {
  [key: string]: string
}

export interface LearningCategoryManagementModel {
  id: string
  orderNumber: number
  color: string
  backgroundColor: string
  icon: string
  title: MultilingualString
  description: MultilingualString
  tags: string[]
}

export interface LearningCourseManagementModel {
  id: string
  learningCategoryId: string
  employerId: string | null
  isPublished: boolean
  title: MultilingualString
  description: MultilingualString
  imageUrl: string
  orderNumber: number
  tags: string[]
  deepLinkText: MultilingualString
  deepLinkUrl: MultilingualString
  modules: LearningModuleManagementModel[]
  rating: number
  ratingCount: number
  isTopRated: boolean
  isPopular: boolean
  isNew: boolean
}

export enum LearningModuleType {
  Markdown = 'markdown',
  Action = 'action',
  Quiz = 'quiz'
}

export interface LearningModuleManagementModel {
  id: string
  moduleType: LearningModuleType
  learningCourseId: string
  isPublished: boolean
  title: MultilingualString
  orderNumber: number
  estimatedCompletionTime: string
}

export interface LearningModuleMarkdownManagementModel extends LearningModuleManagementModel {
  content: MultilingualString
}

export interface LearningModuleActionManagementModel extends LearningModuleManagementModel {
  context: MultilingualString
  prompt: MultilingualString
  description: MultilingualString
  deepLinkText: MultilingualString
  deepLinkUrl: MultilingualString
}

export interface LearningModuleQuizManagementModel extends LearningModuleManagementModel {
  questions: LearningModuleQuizQuestionModel[]
}

export interface LearningCategoryCreateOrUpdateModel {
  orderNumber: number
  color: string
  backgroundColor: string
  icon: string
  title: MultilingualString
  description: MultilingualString
  tags: string[]
}

export interface LearningCourseCreateOrUpdateModel {
  learningCategoryId: string
  employerId: string | null
  title: MultilingualString
  description: MultilingualString
  imageUrl: string
  orderNumber: number
  tags: string[]
  deepLinkText: MultilingualString | null
  deepLinkUrl: MultilingualString | null
}

export interface LearningModuleCreateOrUpdateModel {
  moduleType: LearningModuleType
  title: MultilingualString
  orderNumber: number
  estimatedCompletionTime: string
}

export interface LearningModuleMarkdownCreateOrUpdateModel extends LearningModuleCreateOrUpdateModel {
  content: MultilingualString
}

export interface LearningModuleActionCreateOrUpdateModel extends LearningModuleCreateOrUpdateModel {
  context: MultilingualString
  prompt: MultilingualString
  description: MultilingualString
  deepLinkText: MultilingualString | null
  deepLinkUrl: MultilingualString | null
}

export interface LearningModuleQuizCreateOrUpdateModel extends LearningModuleCreateOrUpdateModel {
  questions: LearningModuleQuizQuestionModel[]
}

export enum LearningModuleQuizQuestionType {
  YesNo = 'yesNo',
  SingleChoice = 'singleChoice',
  MultipleChoice = 'multipleChoice'
}

export interface LearningModuleQuizQuestionModel {
  id: string | null
  published: boolean
  type: LearningModuleQuizQuestionType
  question: MultilingualString
  explanation: MultilingualString
  orderNumber: number
  alternatives: LearningModuleQuizQuestionAlternativeModel[]
}

export interface LearningModuleQuizQuestionAlternativeModel {
  id: string | null
  published: boolean
  answer: MultilingualString
  description: MultilingualString
  isCorrect: boolean,
  orderNumber: number
}

export interface EmployerPayRunDashboardModel {
  id: string
  paydayId: string
  employerDeductionPaymentBankAccountTransactionId: string | null
  employerDeductionPaymentBankAccountBatchTransactionItemId: string | null
  employerId: string
  customerId: string
  customerName: string
  paymentDateString: string
  paymentDate: string
  offCycle: boolean
}

export interface CommunicationOptOutsModel {
  userIds: string[]
  employeeIds: string[]
}

