import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import { Colors } from '../../constants/colors'
import { EmployeeListItemModel } from '../../redux/types'
import Card from '../Card'
import Pagination from '../Pagination'

export interface Props {
  employeesOnPage?: EmployeeListItemModel[]
  pageIndex: number
  pageSize: number
  totalCount: number
  onPageChange: (pageIndex: number) => void
}

const EmployeeList = ({ employeesOnPage, pageIndex, pageSize, totalCount, onPageChange }: Props) => {
  const styles = useStyles()

  const renderHeader = () => {
    return (
      <Card className={`${styles.container} ${styles.header} card`}>
        <div className={styles.cell}>
          <h6>Name</h6>
        </div>
        <div className={styles.cell}>
          <h6>Employment number</h6>
        </div>
        <div className={styles.cell}>
          <h6>Identification number</h6>
        </div>
        <div className={`${styles.cell} ${styles.statusContainer}`}>
        <h6>Status</h6>
        </div>
      </Card>
    )    
  }

  const renderListItems = () => {
    return employeesOnPage?.map((item, index) => {
      return (
        <Link to={{pathname: `/employees/${item?.id}`}} key={`employee_${index}`}>
          <Card className={styles.container}>
            <div className={styles.cell}>
              {item?.givenName} {item?.familyName}
            </div>
            <div className={styles.cell}>
              {item?.externalId}
            </div>
            <div className={styles.cell}>
              {item?.identificationNumber}
            </div>
            <div className={`${styles.cell} ${styles.statusContainer} ${styles.status}`}>
              <span>{item?.status}</span>
            </div>
          </Card>
        </Link>
      )
    })
  }

  return (
    <div>
      {renderHeader()}
      {renderListItems()}
      <div className={styles.pagination}>
        <Pagination
          pageIndex={pageIndex}
          itemCount={totalCount}
          pageSize={pageSize}
          siblingCount={1}
          onPageChange={onPageChange}
        />
      </div>
    </div>  
  )
}

export default EmployeeList

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    padding: '3px 20px',
    margin: '10px 0',
    transition: '0.4s',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  },
  header: {
    margin: '30px 0 20px 0',
  },
  cell: {
    flex: 1,
    padding: '0px 10px'
  },
  statusContainer: {
    flex: '0 0 120px',
  },
  status: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: 5,
    padding: '2px 5px',
    backgroundColor: Colors.darkGray3,
    '& span': {
      fontFamily: 'Aestetico-Regular',
      color: Colors.darkGray4,
      fontSize: 14,
    }
  },
  pagination: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center'
  }
})
