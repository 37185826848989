import { restConfig } from "../authConfig";
import { useAppDispatch } from "../redux/hooks";
import { employeeDetailsFetched, employeeDetailsFetchFailure, employeesFetched, employeesFetchFailure, loading } from "../redux/slices/employeeSlice";
import { EmployeeListItemModel, EmployeeModel } from '../redux/types';
import { getPaginationDataFromResponse } from "../utils/responseHelper";
import { createUrlWithQueryString } from "../utils/url";
import useFetchClient from "./useFetchClient";

const useEmployeeActions = () => {
  const fetchClient = useFetchClient()
  const dispatch = useAppDispatch()

  const getEmployees = (employerId: string, searchTerm: string, pageIndex: number, pageSize: number) => {
    dispatch(loading())   

    const params = {
      employerId: employerId,
      query: searchTerm,
      pageNumber: pageIndex,
      pageSize: pageSize
    }
    const url = createUrlWithQueryString(restConfig.routes.employees, params)

    return fetchClient.get(url)
      .then(response => {
          const paginationData = getPaginationDataFromResponse(response.raw)
          dispatch(employeesFetched({ items: response.data as EmployeeListItemModel[], totalCount: paginationData?.totalItemCount ?? 0}))
      })
      .catch(error => {
          console.log('getEmployees error:', error)
          dispatch(employeesFetchFailure(error))
      })
  }

  const getEmployeeDetails = (employeeId: string) => {
    dispatch(loading())   

    const url = restConfig.routes.employees + `/${employeeId}`

    return fetchClient.get(url)
      .then(response => {
          dispatch(employeeDetailsFetched(response.data as EmployeeModel))
      })
      .catch(error => {
          console.log('getEmployeeDetails error:', error)
          dispatch(employeeDetailsFetchFailure(error))
      })
  }

  return {
    getEmployees,
    getEmployeeDetails
  }
}

export default useEmployeeActions