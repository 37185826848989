import { useState, useEffect } from 'react'
import Modal from '../Modal'
import { PortalUserModel, PortalRole, PortalUserEditModel } from '../../redux/types'
import InputField from '../InputField'
import Switch from '../Switch'
import S2 from '../typography/S2'
import { createUseStyles } from 'react-jss'

interface Props {
    initialValue?: PortalUserModel | null
    employerId: string
    onSaveClick?: (portalUser: PortalUserEditModel) => void
    onCancelClick?: () => void
    visible: boolean
}

const EditPortalUserModal = ({
    initialValue,
    employerId,
    onSaveClick,
    onCancelClick,
    visible
}: Props) => {
    const styles = useStyles()
    const [portalUser, setPortalUser] = useState<PortalUserEditModel>({portalUserId: null, email: '', roles: []})

    useEffect(() => {
        if (initialValue) {
            setPortalUser({
                portalUserId: initialValue.portalUserId,
                email: initialValue.email,
                roles: [...initialValue.roles],
            })
        }           
    }, [initialValue])

    const handleOnSave = () => {
        portalUser && onSaveClick && onSaveClick(portalUser)
        setPortalUser({portalUserId: null, email: '', roles: []})
    }

    const handleOnCancel = () => {
        onCancelClick && onCancelClick()
        setPortalUser({portalUserId: null, email: '', roles: []})
    }

    const handleOnChange = (propName: string, value: any) => {
        if (portalUser)
            setPortalUser({...portalUser, [propName]: value})
    }

    const handleRoleChange = (role: PortalRole, value: boolean) => {
        if (portalUser) {
            if (value) 
                setPortalUser({...portalUser, roles: [...portalUser.roles ?? [], role]})
            else
                setPortalUser({...portalUser, roles: portalUser.roles?.filter(x => x != role)})
        }  
    }

    return (
        <Modal
            title='Add/edit portal user'
            show={visible}
            okButtonTitle='Save'
            cancelButtonTitle='Cancel'
            onHide={() => handleOnCancel()}
            onOkClick={() => handleOnSave()}
            onCancelClick={() => handleOnCancel()}
        >
            <InputField
                id='email'
                disabled={initialValue != null}
                value={portalUser?.email ?? ''}
                onChange={(event) => handleOnChange('email', event.target.value)}
                label='Email'
                placeholder=''
            />

            <S2 className={styles.label}>Roles</S2>

            <Switch 
                id='owner'
                key='owner'
                checked={portalUser?.roles?.includes('Owner') ?? false} 
                label='Portal owner' 
                onChange={() => handleRoleChange('Owner', !(portalUser?.roles?.includes('Owner') ?? false))} 
            />
            <Switch 
                id='admin'
                key='admin'
                checked={portalUser?.roles?.includes('Admin') ?? false} 
                label='Admin' 
                onChange={() => handleRoleChange('Admin', !(portalUser?.roles?.includes('Admin') ?? false))} 
            />
            <Switch 
                id='payrollAdmin'
                key='payrollAdmin'
                checked={portalUser?.roles?.includes('PayrollAdmin') ?? false} 
                label='Payroll admin' 
                onChange={() => handleRoleChange('PayrollAdmin', !(portalUser?.roles?.includes('PayrollAdmin') ?? false))} 
            />
            <Switch 
                id='activationAdmin'
                key='activationAdmin'
                checked={portalUser?.roles?.includes('ActivationAdmin') ?? false} 
                label='Activation admin' 
                onChange={() => handleRoleChange('ActivationAdmin', !(portalUser?.roles?.includes('ActivationAdmin') ?? false))} 
            />
            <Switch 
                id='financeAdmin'
                key='financeAdmin'
                checked={portalUser?.roles?.includes('FinanceAdmin') ?? false} 
                label='Finance admin' 
                onChange={() => handleRoleChange('FinanceAdmin', !(portalUser?.roles?.includes('FinanceAdmin') ?? false))} 
            />
            <Switch 
                id='financeContact'
                key='financeContact'
                checked={portalUser?.roles?.includes('FinanceContact') ?? false} 
                label='Finance contact' 
                onChange={() => handleRoleChange('FinanceContact', !(portalUser?.roles?.includes('FinanceContact') ?? false))} 
            />
            <Switch 
                id='payrollContact'
                key='payrollContact'
                checked={portalUser?.roles?.includes('PayrollContact') ?? false} 
                label='Payroll contact' 
                onChange={() => handleRoleChange('PayrollContact', !(portalUser?.roles?.includes('PayrollContact') ?? false))} 
            />
            <Switch 
                id='technicalContact'
                key='technicalContact'
                checked={portalUser?.roles?.includes('TechnicalContact') ?? false} 
                label='Technical contact' 
                onChange={() => handleRoleChange('TechnicalContact', !(portalUser?.roles?.includes('TechnicalContact') ?? false))} 
            />
        </Modal>
    )
}

export default EditPortalUserModal

const useStyles = createUseStyles({
    label: {
      marginTop: 10
    }
  })
  