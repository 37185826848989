import { useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import Modal from '../Modal'
import InputField from '../InputField'
import Select from '../Select'
import {  
    Language, 
    CustomerModel,
    UpdateCustomerModel
} from '../../redux/types'
import S1 from '../typography/S1'

interface Props {
    initialValue?: CustomerModel
    onSaveClick?: (updateModel: UpdateCustomerModel) => void
    onCancelClick?: () => void
    visible: boolean
}

const EditCustomerModal = ({
    initialValue,
    onSaveClick,
    onCancelClick,
    visible
}: Props) => {
    const styles = useStyles()
    const [updateModel, setUpdateModel] = useState<UpdateCustomerModel>()

    useEffect(() => {
        if (initialValue) {
            setUpdateModel({
                companyRegistrationNumber: initialValue.companyRegistrationNumber,
                name: initialValue.name,
                legalName: initialValue.legalName,
                agreementNumber: initialValue.agreementNumber,
                language: initialValue.language,
                invoiceEmailAddress: initialValue.invoiceEmailAddress,
                timeZoneId: initialValue.timeZoneId,
            })
        }
    }, [initialValue])

    const handleOnSave = () => {
        if (updateModel) {
            onSaveClick && onSaveClick(updateModel)
        }
    }

    const handleOnClose = () => {
        onCancelClick && onCancelClick()
    }

    const handleOnChange = (propName: string, value: any) => {
        if (updateModel) {
            setUpdateModel({...updateModel, [propName]: value === '' ? null : value})
        }
    }

    const getLanguageOptions = () => {
        return Object.keys(Language).filter(v => isNaN(Number(v)))
            .map((value) => { return { name: value, value: value }
        })
    }

    return (
        <Modal
            title='Edit customer'
            show={visible}
            okButtonTitle='Save'
            cancelButtonTitle='Cancel'
            onHide={() => handleOnClose()}
            onOkClick={() => handleOnSave()}
            onCancelClick={() => handleOnClose()}
        >
            <S1 className={styles.sectionTitle}>General</S1>
            <InputField
                id='companyRegistationNumber'
                disabled={true}
                value={updateModel?.companyRegistrationNumber ?? ''}
                label='Company registration number'
                placeholder=''
              />
            <InputField
                id='legalName'
                value={updateModel?.legalName ?? ''}
                onChange={(event) => handleOnChange('legalName', event.target.value)}
                label='Legal name'
                placeholder=''
              />
            <InputField
                id='name'
                value={updateModel?.name ?? ''}
                onChange={(event) => handleOnChange('name', event.target.value)}
                label='Name'
                placeholder=''
              />
            <InputField
                id='agreementNumber'
                value={updateModel?.agreementNumber ?? ''}
                onChange={(event) => handleOnChange('agreementNumber', event.target.value)}
                label='Agreement number'
                placeholder=''
              />
              <InputField
                id='invoiceEmailAddress'
                value={updateModel?.invoiceEmailAddress ?? ''}
                onChange={(event) => handleOnChange('invoiceEmailAddress', event.target.value)}
                label='Invoice address'
                placeholder=''
              />
            <InputField
                id='timeZoneId'
                disabled={true}
                value={updateModel?.timeZoneId ?? ''}
                label='Time zone'
                placeholder=''
              />
            <Select
                id='language'
                key='language'
                label='Language'
                options={getLanguageOptions()}
                selectedValue={updateModel?.language.toString()}
                onSelect={(value) => handleOnChange('language', Language[value as keyof typeof Language])}
            />
        </Modal>
    )
}

export default EditCustomerModal

const useStyles = createUseStyles({
    container: {
        alignItems: 'center',
        textAlign: 'center',
        padding: '12px 20px',
        margin: '10px 0',
    },
    sectionTitle: {
        marginBottom: 15
    }
})
