import Card from '../Card'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../constants/colors'
import { PortalUserModel } from '../../redux/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons'
import Badge from '../Badge'

interface Props {
  portalUsers: PortalUserModel[]
  onDeleteClick?: (contact: PortalUserModel) => void
  onEditClick?: (contact: PortalUserModel) => void
}

const PortalUserList = ({ onDeleteClick, onEditClick, portalUsers }: Props) => {
  const styles = useStyles()

  const renderHeader = () => {
    return (
      <Card className={`${styles.container} ${styles.header} card`}>
        <div className={styles.cell}>
          <p><span>Email</span></p>
        </div>
        <div className={styles.cell}>
          <p><span>Name</span></p>
        </div>
        <div className={styles.cell}>
          <p><span>Phone</span></p>
        </div>
        <div className={styles.badgeCell}>
          <p><span>Roles</span></p>
        </div>
        <div className={`${styles.cell} ${styles.iconCell} ${styles.buttonCell}`}>
        </div>
        <div className={`${styles.cell} ${styles.iconCell} ${styles.buttonCell}`}>
        </div>
      </Card>
    )    
  }

  const renderListItems = () => {
    return portalUsers?.map((item, index) => {
      return (
        <Card className={`${styles.container} card`} key={`portalUser_${index}`}>
          <div className={styles.cell}>
            <p>{item.email}</p>
          </div>
          <div className={styles.cell}>
            <p>{item.givenName} {item.familyName}</p>
          </div>
          <div className={styles.cell}>
            <p>{item.phoneNumber}</p>
          </div>
          <div className={styles.badgeCell}>
            {renderRoleBadges(item)}
          </div>
          <div className={`${styles.cell} ${styles.iconCell} ${styles.buttonCell}`}>
            <FontAwesomeIcon
              className={styles.button}
              icon={faEdit}
              color={Colors.euro_600}
              title='Edit'
              onClick={() => onEditClick && onEditClick(item)}
            />
          </div>
          <div className={`${styles.cell} ${styles.iconCell} ${styles.buttonCell}`}>
            <FontAwesomeIcon
              className={styles.button}
              icon={faTrashAlt}
              color={Colors.euro_600}
              title='Delete'
              onClick={() => onDeleteClick && onDeleteClick(item)}
            />
          </div>
        </Card>
      )
    })
  }


  const renderRoleBadges = (user: PortalUserModel) => {
    return user.roles?.map((role, index) => {
        return (
            <Badge 
                key={`roleBadge${index}`}
                text={role}
                backgroundColor={Colors.darkGray3}
                textColor={Colors.darkGray4}
            />
        )
    })
}

  return (
    <div>
      {renderHeader()}
      {renderListItems()}
    </div>
  )
}

export default PortalUserList

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    padding: '3px 3px',
    margin: '5px 0',
    transition: '0.4s',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  },
  '& p': {
      fontSize: 12,
      margin: 0,
      '& span': {
        fontFamily: 'Aestetico-Bold',
        color: Colors.euro_600,
      }
  },
  header: {
    margin: '30px 0 20px 0',
  },
  flagged: {
      backgroundColor: Colors.red,
  },
  cell: {
    flex: 1,
    padding: '0px 5px'
  },
  iconCell: {
    textAlign: 'center',
  },
  buttonCell: {
    flex: '0 0 40px'
  },
  badgeCell: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexWrap: 'wrap',
    justifyContent: 'flex-start'
  },
  button: {
      transition: '0.4s',
      '&:hover': {
        color: Colors.euro5,
        cursor: 'pointer',
      }
    },
})