import {useEffect, useState} from 'react'
import {createUseStyles} from 'react-jss'
import {useHistory, useParams} from "react-router-dom";
import Switch from '../components/Switch'
import {Colors} from '../constants/colors'
import Button from '../components/Button'
import CardSection from '../components/CardSection'
import DetailsCard from '../components/DetailsCard'
import {
  UpdateCustomerModel,
} from '../redux/types'
import ConfirmModal from '../components/ConfirmModal'
import ApiKeyModal from '../components/ApiKeyModal'
import {useAppDispatch, useAppSelector} from '../redux/hooks';
import FullScreenLoadingIndicator from '../components/FullScreenLoadingIndicator';
import Divider from '../components/Divider';
import {faArrowLeft, faExclamationTriangle, faFileInvoice, faUsers} from "@fortawesome/free-solid-svg-icons";
import NavLink from '../components/NavLink';
import useProtectCustomerActions from '../hooks/useProtectCustomerActions';
import {titleMarginBottom} from '../constants/layout';
import useCustomerActions from '../hooks/useCustomerActions';
import EditCustomerModal from '../components/EditCustomerModal';
import useEmployerActions from '../hooks/useEmployerActions';
import Link from '../components/Link';
import useDidUpdateEffect from '../hooks/useDidUpdateEffect';
import { employerCreationHandled } from '../redux/slices/employerSlice';
import useInvoicePayoutProviderActions from '../hooks/useInvoicePayoutProviderActions';
import { invoicePayoutProviderCreationHandled } from '../redux/slices/invoicePayoutProviderSlice';

const CustomerDetails = () => {
  const styles = useStyles()
  const params = useParams<{id: string}>()
  const history = useHistory();
  const dispatch = useAppDispatch()

  const [confirmLockCustomerModalVisible, setConfirmLockCustomerModalVisible] = useState(false)
  const [confirmCreateEmployerModalVisible, setConfirmCreateEmployerModalVisible] = useState(false)
  const [confirmCreateInvoicePayoutProviderModalVisible, setConfirmCreateInvoicePayoutProviderModalVisible] = useState(false)
  const [apiKeyModalVisible, setApiKeyModalVisible] = useState(false)
  const [updateCustomerModalVisible, setUpdateCustomerModalVisible] = useState(false)

  const loading = useAppSelector(state => state.customer.loading)
  const customer = useAppSelector(state => state.customer.customerDetails)
  const protectCustomer = useAppSelector(state => state.protectCustomer.customerDetails)
  const createdEmployerId = useAppSelector(state => state.employer.createdEmployerId)
  const createdInvoicePayoutProviderId = useAppSelector(state => state.invoicePayoutProvider.createdProviderId)
  const companiesWithUnhandledChanges = useAppSelector(state => state.companyMonitor.companiesWithUnhandledChanges)

  const {
    getCustomerDetails,
    setLockState,
    updateCustomer
  } = useCustomerActions()

  const { createEmployer } = useEmployerActions()
  const { createInvoicePayoutProvider } = useInvoicePayoutProviderActions()
  const { getProtectCustomer } = useProtectCustomerActions()

  useEffect(() => {
    if (customer?.id == null || customer.id !== params.id) {
      getCustomerDetails(params.id)
    }
  }, [])

  useEffect(() => {
    if (protectCustomer == null || protectCustomer.id !== params.id) {
      getProtectCustomer(params.id)
    }
  }, [])

  useDidUpdateEffect(() => {
    if (createdEmployerId) {
      history.push(`/employers/${createdEmployerId}`)
      dispatch(employerCreationHandled())
    } 
  }, [createdEmployerId])

  useDidUpdateEffect(() => {
    if (createdInvoicePayoutProviderId) {
      history.push(`/invoicepayoutproviders/${createdInvoicePayoutProviderId}`)
      dispatch(invoicePayoutProviderCreationHandled())
    } 
  }, [createdInvoicePayoutProviderId])

  const handleLockStateChange = () => {
    const isLock = customer?.state === 'active'
    if (isLock) {
      setConfirmLockCustomerModalVisible(true)
    } else {
      setLockState(customer?.id as string, false)
    }
  }

  const handleLockCustomerConfirmed = () => {
    setLockState(customer?.id as string, true)
    setConfirmLockCustomerModalVisible(false)
  }

  const handleCreateEmployerConfirmed = () => {
    if (customer) {
      createEmployer({customerId: customer.id})
    }
    setConfirmCreateEmployerModalVisible(false)
  }

  const handleCreateInvoicePayoutProviderConfirmed = () => {
    if (customer) {
      createInvoicePayoutProvider({customerId: customer.id})
    }
    setConfirmCreateInvoicePayoutProviderModalVisible(false)
  }

  const handleCustomerUpdated = (updateModel: UpdateCustomerModel) => {
    updateCustomer(customer?.id as string, updateModel)
    setUpdateCustomerModalVisible(false)
  }

  const renderCompanyInformationCardBody = () => {
    return (
      <div>
        <p>Market: <span>{customer?.marketName}</span></p>
        <p>Registration number: <span>{customer?.companyRegistrationNumber}</span></p>
        <p>Legal name: <span>{customer?.legalName}</span></p>
        <p>Display name: <span>{customer?.name}</span></p>
        <p>State: <span>{customer?.state}</span></p>
        <p>Agreement number: <span>{customer?.agreementNumber}</span></p>
        <p>Invoice address: <span>{customer?.invoiceEmailAddress}</span></p>
        <p>Language: <span>{customer?.language}</span></p>
        <p>Time zone: <span>{customer?.timeZoneId}</span></p>
      </div>
    )
  }

  const renderLockedSwitch = () => {
    const isLocked = customer?.state === 'locked'
    return (
      <Switch
        id='lockSwitch'
        key='lockSwitch'
        checked={isLocked}
        label={isLocked ? 'Manual lock on' : 'Manual lock off'}
        onChange={() => handleLockStateChange()} />
    )
  }

  const renderMonitoringLink = () => {
    const showWarning = companiesWithUnhandledChanges.find(x => x.marketId == customer?.marketId && x.companyRegistrationNumber == customer.companyRegistrationNumber) != undefined

    return <NavLink
      icon={ showWarning ? faExclamationTriangle : undefined }
      iconColor={Colors.yellow}
      title='Monitoring'
      onClick={() => history.push(`/companymonitor/${customer?.marketId}/${customer?.companyRegistrationNumber}`)} />
  }

  const renderProtectLink = () => {
    const showWarning = (protectCustomer?.issues.filter(x => !x.closed).length ?? 0) > 0

    return <NavLink
      icon={ showWarning ? faExclamationTriangle : undefined }
      iconColor={ Colors.red }
      title='Protect'
      onClick={() => history.push(`/protect/customers/${params.id}`)} />
  }

  return (
    <div className={styles.container}>
      <h1>{customer?.legalName}</h1>
      <div className={styles.actionBar}>
        <div className={styles.actionBarCell} style={{justifyContent: 'flex-start'}}>
          <NavLink title='Back' icon={faArrowLeft} onClick={() => history.goBack()} />
        </div>
        <div className={styles.actionBarCell} style={{justifyContent: 'center'}}>
          <div className={styles.navLinkContainer}>
            { renderMonitoringLink() }
            { renderProtectLink() }
          </div>
        </div>
        <div className={styles.actionBarCell} style={{justifyContent: 'flex-end'}}>
            <div style={{paddingBottom: 8}}>
              { renderLockedSwitch() }
            </div>
            <Button
              title='Generate API key'
              onClick={() => setApiKeyModalVisible(true)} />
        </div>

      </div>
      <CardSection>
        <DetailsCard onClick={() => setUpdateCustomerModalVisible(true)}>
          <h6>General information</h6>
          {renderCompanyInformationCardBody()}
        </DetailsCard>
        <DetailsCard iconType='Add' onClick={customer?.employer != null ? undefined : () => setConfirmCreateEmployerModalVisible(true)}>
          <h6>Employer</h6>
          {customer?.employer && 
            <div>
              <p>Employer: <Link to={`/employers/${customer?.employer?.id}`}>{customer?.name}</Link></p>
              <p>State: <span>{customer?.employer?.state}</span></p>
            </div>
          }
          {!customer?.employer && 
            <div>
              <p>Customer has no employer configured</p>
            </div>
          }
        </DetailsCard>
        <DetailsCard iconType='Add' onClick={customer?.invoicePayoutProvider != null ? undefined : () => setConfirmCreateInvoicePayoutProviderModalVisible(true)}>
          <h6>Invoice payout provider</h6>
          {customer?.invoicePayoutProvider && 
            <div>
              <p>Invoice payout provider: <Link to={`/invoicepayoutproviders/${customer?.invoicePayoutProvider?.id}`}>{customer?.name}</Link></p>
              <p>State: <span>{customer?.invoicePayoutProvider?.state}</span></p>
            </div>
          }
          {!customer?.invoicePayoutProvider && 
            <div>
              <p>Customer has no invoice payout provider configured</p>
            </div>
          }
        </DetailsCard>
      </CardSection>

      <Divider height={50}/>
      <ConfirmModal
        visible={confirmLockCustomerModalVisible}
        confirmText={`Are you sure you want to lock this customer?`}
        onConfirmClick={handleLockCustomerConfirmed}
        onCancelClick={() => setConfirmLockCustomerModalVisible(false)}
      />
      <ConfirmModal
        visible={confirmCreateEmployerModalVisible}
        confirmText={`Are you sure you want to create an employer for this customer?`}
        onConfirmClick={handleCreateEmployerConfirmed}
        onCancelClick={() => setConfirmCreateEmployerModalVisible(false)}
      />
      <ConfirmModal
        visible={confirmCreateInvoicePayoutProviderModalVisible}
        confirmText={`Are you sure you want to create an invoice payout provider for this customer?`}
        onConfirmClick={handleCreateInvoicePayoutProviderConfirmed}
        onCancelClick={() => setConfirmCreateInvoicePayoutProviderModalVisible(false)}
      />
      <ApiKeyModal
        visible={apiKeyModalVisible}
        customerId={customer?.id}
        onCloseClick={() => setApiKeyModalVisible(false)}
      />
      <EditCustomerModal
        visible={updateCustomerModalVisible}
        initialValue={customer ?? undefined}
        onCancelClick={() => setUpdateCustomerModalVisible(false)}
        onSaveClick={handleCustomerUpdated}
      />
      <FullScreenLoadingIndicator visible={loading} />
    </div>
  )
}

export default CustomerDetails

const useStyles = createUseStyles({
  container: {
    '& h1': {
      marginBottom: titleMarginBottom
    },
    '& h5': {
      fontFamily: 'Aestetico-Regular',
      color: Colors.darkDefault,
    }
  },
  actionBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: -8
  },
  actionBarCell: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 15,
    alignItems: 'center',
    width: '33%'
  },
  navLinkContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 10
  },
  cardColumnContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 10
  },
  largeButton: {
    fontSize: "xxx-large",
    alignSelf: "center",
    margin: "20px",
    flexGrow: 1,
    transition: '0.4s',
    '&:hover': {
      color: Colors.euro5,
      cursor: 'pointer',
    }
  }
})
