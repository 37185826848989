import { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { useHistory, useParams } from "react-router-dom";
import CardSection from '../components/CardSection'
import DetailsCard from '../components/DetailsCard'
import {formatDateString, formatDateTimeString} from '../utils/dateHelper'
import useEmployeeActions from '../hooks/useEmployeeActions';
import { useAppSelector } from '../redux/hooks';
import FullScreenLoadingIndicator from '../components/FullScreenLoadingIndicator';
import ShiftListComponent from '../components/ShiftList';
import PayDeviationListComponent from '../components/PayDeviationList';
import NavLink from '../components/NavLink';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Link from '../components/Link';
import { titleMarginBottom } from '../constants/layout';
import Divider from "../components/Divider";
import Switch from '../components/Switch';
import useCommunicationOptOutActions from '../hooks/useCommunicationOptOutActions';

const EmployeeDetails = () => {
  const styles = useStyles()
  const params = useParams<{id: string}>()
  const history = useHistory();

  const { getEmployeeDetails } = useEmployeeActions()
  const { setCommunicationOptOutStateForEmployee } = useCommunicationOptOutActions()

  const loading = useAppSelector(state => state.employee.loading)
  const employeeDetails = useAppSelector(state => state.employee.employeeDetails)
  const optOuts = useAppSelector(state => state.optOut.optOuts)

  useEffect(() => {
    if (employeeDetails == null || employeeDetails.id !== params.id) {
      getEmployeeDetails(params.id)
    }
  }, [])

  const notSpecified = 'Not specified'
  const inherited = '(inherited)'

  const renderCommunicationOptOutSwitch = () => {
    return (
      <Switch
        id='communicationOptOutSwitch'
        key='communicationOptOutSwitch'
        checked={(optOuts?.employeeIds.find(x => x == employeeDetails?.id) != undefined) ?? false}
        label='Communication opt-out'
        onChange={() => handleCommunicationOptOutStateChange()} />
    )
  }

  const handleCommunicationOptOutStateChange = () => {
    if (employeeDetails) {
      var currentState = (optOuts?.employeeIds.find(x => x == employeeDetails?.id) != undefined) ?? false
      setCommunicationOptOutStateForEmployee(employeeDetails.id as string, !currentState)
    } 
  }

  const renderUserLink = () => {
    if (employeeDetails?.connectedUser)
      return (
        <Link to={`/users/${employeeDetails.connectedUser.id}`}>{employeeDetails.connectedUser.givenName} {employeeDetails.connectedUser.familyName}</Link>
      )
    else
      return (<span>Not connected</span>)
  }

  const renderPayoutSettingsCardBody = () => {
    return (
      <div>
      <p>Min amount per payout: <span>{employeeDetails?.payoutSettings?.minAmountPerPayout || `${employeeDetails?.effectivePayoutSettings?.minAmountPerPayout} ${inherited}`}</span></p>
      <p>Max amount per period: <span>{employeeDetails?.payoutSettings?.maxAmountPerPayday || `${employeeDetails?.effectivePayoutSettings?.maxAmountPerPayday} ${inherited}`}</span></p>
      <p>Max percent of earnings: <span>{employeeDetails?.payoutSettings?.maxPercentPerPayday || `${employeeDetails?.effectivePayoutSettings?.maxPercentPerPayday} ${inherited}`}</span></p>
      {/* <p>Max amount per payout: <span>{employer?.payoutSettings?.maxAmountPerPayout || notSpecified}</span></p> */}
      <p>Max number of payouts per period: <span>{employeeDetails?.payoutSettings?.maxNumberOfPayoutsPerPayday || `${employeeDetails?.effectivePayoutSettings?.maxNumberOfPayoutsPerPayday} ${inherited}`}</span></p>
      <p>Payout fee: <span>{employeeDetails?.payoutSettings?.fee || `${employeeDetails?.effectivePayoutSettings?.fee} ${inherited}`}</span></p>
      <p>Payout currency: <span>{employeeDetails?.payoutSettings?.currency?.toUpperCase() || `${employeeDetails?.effectivePayoutSettings?.currency?.toUpperCase()} ${inherited}`}</span></p>
      <p>Enable custom shift settings: <span>{employeeDetails?.payoutSettings?.enableCustomShiftSettings?.toString() || `${employeeDetails?.effectivePayoutSettings?.enableCustomShiftSettings} ${inherited}`}</span></p>
      <p>Max percent for custom shifts: <span>{employeeDetails?.payoutSettings?.maxCustomPercentPerPayday || `${employeeDetails?.effectivePayoutSettings?.maxCustomPercentPerPayday} ${inherited}`}</span></p>
    </div>
    )
  }

  return (
    <div className={styles.container}>
      <h1>Employee: {employeeDetails?.givenName} {employeeDetails?.familyName}</h1>
      <div className={styles.actionBar}>
        <div className={styles.actionBarCell} style={{justifyContent: 'flex-start'}}>
          <NavLink title='Back' icon={faArrowLeft} onClick={() => history.goBack()} />
        </div>
        <div className={styles.actionBarCell} style={{justifyContent: 'flex-end'}}>
            <div style={{paddingBottom: 8}}>
              { renderCommunicationOptOutSwitch() }
            </div>
        </div>
      </div>
      <CardSection>
        <DetailsCard>
          <h6>General information</h6>
          <p>Employer: <Link to={`/employers/${employeeDetails?.employerId}`}>{employeeDetails?.employerName}</Link></p>
          <p>Employment number: <span>{employeeDetails?.externalId || notSpecified}</span></p>
          <p>Name: <span>{employeeDetails?.givenName || notSpecified} {employeeDetails?.familyName}</span></p>
          <p>Identification number: <span>{employeeDetails?.identificationNumber || notSpecified}</span></p>
          <p>Email: <span>{employeeDetails?.email || notSpecified}</span></p>
          <p>Phone number: <span>{employeeDetails?.phoneNumber || notSpecified}</span></p>
          <p>Type: <span>{employeeDetails?.type || notSpecified}</span></p>
          <p>Pay calculation type: <span>{employeeDetails?.payCalculationType || notSpecified}</span></p>
          <p>Payroll report identifier: <span>{employeeDetails?.payrollFileIdentifier || notSpecified}</span></p>
          <p>Bank account number: <span>{employeeDetails?.bankAccountNumber || notSpecified}</span></p>
          <p>Department: <span>{employeeDetails?.department || notSpecified}</span></p>
          <p>Category: <span>{employeeDetails?.category || notSpecified}</span></p>
          <p>Location: <span>{employeeDetails?.location || notSpecified}</span></p>
          <p>Cost center: <span>{employeeDetails?.costCenter || notSpecified}</span></p>
          <p>Salary: <span>{employeeDetails?.grossSalary?.amount.toLocaleString('sv-SE')} {employeeDetails?.grossSalary?.currencyCode.toUpperCase()}</span></p>
          <p>Hourly wage: <span>{employeeDetails?.grossHourlyWage?.amount.toLocaleString('sv-SE')} {employeeDetails?.grossHourlyWage?.currencyCode.toUpperCase()}</span></p>
          <p>Tax table identifier: <span>{employeeDetails?.taxSettings?.taxTableIdentifier || notSpecified}</span></p>
          <p>Fixed tax rate percent: <span>{employeeDetails?.taxSettings?.fixedTaxRatePercent || notSpecified}</span></p>
          <p>Payout tax in percent: <span>{employeeDetails?.taxSettings?.payoutTaxInPercent || notSpecified}</span></p>
          <p>Vacation pay percentage: <span>{employeeDetails?.handleVacationPay ? employeeDetails?.vacationPayPercent : notSpecified}</span></p>
        </DetailsCard>
        <DetailsCard>
          <h6>State and user information</h6>
          <p>Non-eligible: <span>{employeeDetails?.nonEligible ? 'yes' : 'no'}</span></p>
          <p>State: <span>{employeeDetails?.status || notSpecified}</span></p>
          <p>Current employment start date: <span>{employeeDetails?.startDate ? formatDateTimeString(employeeDetails?.startDate) : notSpecified}</span></p>
          <p>Initial start date: <span>{employeeDetails?.initialStartDate ? formatDateTimeString(employeeDetails?.initialStartDate) : notSpecified}</span></p>
          <p>Activation date: <span>{employeeDetails?.activated ? formatDateTimeString(employeeDetails?.activationDate) : notSpecified}</span></p>
          <p>Termination date: <span>{employeeDetails?.terminationDate ? formatDateTimeString(employeeDetails?.terminationDate) : notSpecified}</span></p>
          {employeeDetails?.paused &&
          <p>Pause date: <span>{formatDateTimeString(employeeDetails?.pauseDate)}</span></p>}
          {employeeDetails?.paused &&
          <p>Pause reason: <span>{employeeDetails?.pauseReason || notSpecified}</span></p>}
          <p>Connected to user: {renderUserLink()}</p>
          {employeeDetails?.connectedUser &&
          <p>Connected date: {formatDateTimeString(employeeDetails.connectedUser.connectedDate)}</p>
          }
          {employeeDetails?.pendingBankAccountActivation &&
          <p>Bank account activation pending: <span>yes</span></p>
          }
          {employeeDetails?.bankAccountActivationFailed &&
          <p>Bank account activation failed: <span>yes</span></p>
          }
          {employeeDetails?.underForeclosure &&
          <p>Under foreclosure: <span>yes</span></p>
          }
          <Divider height={20} />
          <p>Welcome message sent: <span>{employeeDetails?.welcomeMessageSent ? "yes": "no"}</span></p>
          <p>Eligible for activation reminder: <span>{employeeDetails?.isEligibleForActivationReminder ? "yes" : "no"}</span></p>
          <p>Last activation reminder: <span>{employeeDetails?.lastActivationReminderDate ? formatDateString(employeeDetails.lastActivationReminderDate) : notSpecified}</span></p>
          <p>Next activation reminder: <span>{employeeDetails?.nextActivationReminderDate ? formatDateString(employeeDetails.nextActivationReminderDate) : notSpecified}</span></p>
        </DetailsCard>
        <DetailsCard>
          <h6>Payout settings</h6>
          {renderPayoutSettingsCardBody()}
        </DetailsCard>
      </CardSection>
      <h4>Shifts</h4>
      <ShiftListComponent employeeId={employeeDetails?.id} />
      <h4>Deviations</h4>
      <PayDeviationListComponent employeeId={employeeDetails?.id} />
      <FullScreenLoadingIndicator visible={loading} />
    </div>
  )
}

export default EmployeeDetails

const useStyles = createUseStyles({
  container: {
    '& h1': {
      marginBottom: titleMarginBottom
    },
    '& h4': {
      marginTop: '15px'
    }
  },
  actionBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: -8
  },
  actionBarCell: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 15,
    alignItems: 'center',
    width: '33%'
  },
})
