import { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import useEmployeeActions from '../../hooks/useEmployeeActions'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { filterChanged, pageIndexChanged } from '../../redux/slices/employeeSlice'
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect'
import EmployeeFilter from './EmployeeFilter'
import EmployeeList from './EmployeeList'

export interface Props {
    employerId?: string
  }

const EmployeeListComponent = ({ employerId }: Props) => {
  const styles = useStyles()
  const dispatch = useAppDispatch()
  const { getEmployees } = useEmployeeActions()

  const employeesOnPage = useAppSelector(state => state.employee.employeesOnPage)
  const totalCount = useAppSelector(state => state.employee.totalCount)
  const searchTerm = useAppSelector(state => state.employee.searchTerm)
  const searchEmployerId = useAppSelector(state => state.employee.employerId)
  const pageIndex = useAppSelector(state => state.employee.pageIndex)
  const pageSize = useAppSelector(state => state.employee.pageSize)

  useEffect(() => {
    if (employerId && employerId !== searchEmployerId)
      dispatch(filterChanged({ searchTerm: '', employerId: employerId as string}))
  }, [employerId])

  useDidUpdateEffect(() => {
    if (searchEmployerId) {
      getEmployees(searchEmployerId, searchTerm ?? '', pageIndex, pageSize)
    }
  }, [searchEmployerId, pageIndex, searchTerm])

  const onPageChange = (pageIndex: number) => {
    dispatch(pageIndexChanged(pageIndex))
  }

  const onFilterChange = (value: string) => {
    dispatch(filterChanged({ searchTerm: value.toLowerCase(), employerId: employerId as string}))
  }

  return (
      <div className={styles.container}>
        <EmployeeFilter 
          filterValue={searchTerm || ''}
          onFilterChange={onFilterChange}
        /> 
        <EmployeeList 
          employeesOnPage={employeesOnPage}
          pageIndex={pageIndex}
          pageSize={pageSize} 
          totalCount={totalCount}
          onPageChange={onPageChange}
        />
      </div>
    )
}

export default EmployeeListComponent

const useStyles = createUseStyles({
  container: {
      marginTop: 40,
  }
})