import { Container, Navbar, Nav, NavDropdown, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './NavMenu.scss'
import { createUseStyles } from 'react-jss'
import Logo from '../../assets/images/cappy-logo-horizontal-neg.png'
import { Colors } from '../../constants/colors'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react'
import { loginRequest } from '../../authConfig'
import { Breakpoints } from '../../constants/breakpoints'
import { useLocation } from 'react-router-dom'
import { useAppSelector } from '../../redux/hooks'
import NumberDot from '../../components/NumberDot'

const NavMenu = () => {
  const styles = useStyles()
  const { instance } = useMsal()
  const location = useLocation();

  const reconciliationsWithIssuesCount = useAppSelector(state => state.reconciliation.reconciliationsWithIssuesCount)
  const protectIssueCount = useAppSelector(state => state.protectIssues.totalCount)
  const amlFlaggedCompanyRepresentatives = useAppSelector(state => state.amlFlaggedCompanyRepresentative.representatives)
  const amlFlaggedCompanies = useAppSelector(state => state.amlFlaggedCompany.companies)
  const totalAmlFlagCount = (amlFlaggedCompanyRepresentatives?.length ?? 0) + (amlFlaggedCompanies?.length ?? 0)

  const renderNavLinks = () => {
    console.log(location.pathname)

    const path = location.pathname

    return (
      <>
        <AuthenticatedTemplate>
          <Nav.Link as={Link} to="/"><span className={path === '/' ? styles.selected : ''}>Start</span></Nav.Link>
          <Nav.Link as={Link} to="/customers"><span className={path.startsWith('/customer') || path.startsWith('/employ') || path.startsWith('/invoicepayoutprovider') ? styles.selected : ''}>Customers</span></Nav.Link>
          <Nav.Link as={Link} to="/users"><span className={path.startsWith('/users') ? styles.selected : ''}>Users</span></Nav.Link>
          <Nav.Link as={Link} to="/transactions"><span className={path.startsWith('/transactions') ? styles.selected : ''}>Transactions</span></Nav.Link>
          <Nav.Link as={Link} to="/paydays"><span className={path.startsWith('/paydays') ? styles.selected : ''}>Paydays</span></Nav.Link>
          <Nav.Link as={Link} to="/payrun"><span className={path.startsWith('/payrun') ? styles.selected : ''}>Payrun</span></Nav.Link>
          <Nav.Link as={Link} to="/learning"><span className={path.startsWith('/learning') ? styles.selected : ''}>Learning</span></Nav.Link>
          <Nav.Link as={Link} to="/reconciliations">
            <div style={{position: 'relative', display: 'inline-block'}}>
              <span className={path.startsWith('/reconciliations') ? styles.selected : ''}>Reconciliations</span> {reconciliationsWithIssuesCount != null && reconciliationsWithIssuesCount > 0 && <NumberDot className={styles.dot} number={reconciliationsWithIssuesCount} />}
            </div>
          </Nav.Link>
          <NavDropdown title={<div style={{position: 'relative', display: 'inline-block'}}><span className={path.startsWith('/aml') ? styles.selected : ''}>PEP &amp; sanction</span> {totalAmlFlagCount != null && totalAmlFlagCount > 0 && <NumberDot className={styles.dot} number={totalAmlFlagCount} />}</div>} id="collasible-other-dropdown">
            <NavDropdown.Item as={Link} to="/aml/companies">
              <div><div style={{position: 'relative', width: 80}}>{amlFlaggedCompanies != null && amlFlaggedCompanies.length > 0 && <NumberDot className={styles.dot} number={amlFlaggedCompanies.length} />}Companies</div></div>
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/aml/companyrepresentatives">
              <div><div style={{position: 'relative', width: 180}}>{amlFlaggedCompanyRepresentatives != null && amlFlaggedCompanyRepresentatives.length > 0 && <NumberDot className={styles.dot} number={amlFlaggedCompanyRepresentatives.length} />}Representatives</div></div>
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link as={Link} to="/protect/issues">
            <div style={{position: 'relative', display: 'inline-block'}}>
              <span className={path.startsWith('/protect/issues') ? styles.selected : ''}>Protect</span> {protectIssueCount != null && protectIssueCount > 0 && <NumberDot className={styles.dot} number={protectIssueCount} />}
            </div>
          </Nav.Link>
          <NavDropdown title={<span className={path.startsWith('/nordea') || path.startsWith('/taxtables') ? styles.selected : ''}>Misc</span>} id="collasible-other-dropdown">
            <NavDropdown.Item as={Link} to="/taxtables">
              Tax tables
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/nordea">
              Nordea integration
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/fortnox">
              Fortnox integration
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/campaigns">
              Campaigns
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/inappmessages">
              In-app messages
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/whistleblower">
              AML Whistleblower
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/companycheck">
              Company Check
            </NavDropdown.Item>
          </NavDropdown>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Nav.Link as={Link} to="/">Start</Nav.Link>
        </UnauthenticatedTemplate>
      </>
    )
  }

  const renderSignInOutButtons = () => {
    return (
      <>
        <AuthenticatedTemplate>
          <NavDropdown className={styles.dropdown}  title={'Account'} id="login-nav-dropdown">
            <NavDropdown.Item as="button" onClick={() => instance.logoutRedirect()}>Sign out</NavDropdown.Item>
          </NavDropdown>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Button className={styles.button} onClick={() => instance.loginRedirect(loginRequest)}>Sign in</Button>
        </UnauthenticatedTemplate>
      </>
    )
  }


  return (
    <header>
      <Navbar collapseOnSelect expand="lg" className="mb-5">
        <Container>
          <Navbar.Brand as={Link} to="/"><img src={Logo} alt="cappy logo" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <div style={{ flex: 1 }} />
            <Nav className={styles.navContainer}>
              {renderNavLinks()}
              {renderSignInOutButtons()}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}

export default NavMenu

const useStyles = createUseStyles({
  navContainer: {
    '& a.nav-link': {
      color: Colors.darkGray2,
    }
  },
  selected: {
    color: Colors.euro_600,
    borderBottom: '3px solid',
    paddingBottom: '16px'
  },
  dropdown: {
    [`@media (min-width: ${Breakpoints.desktop}px)`]: {
      marginLeft: 20,
      padding: {
        right: 20,
        left: 20,
      },
      borderStyle: 'solid',
      borderWidth: '1px',
      bolderColor: Colors.darkGray3,
      borderRadius: 8,
      '& a.nav-link': {
        color: Colors.darkGray2 + ' !important',
      }
    }
  },
  button: {
    borderRadius: 1,
    color: Colors.darkGray2,
    backgroundColor: Colors.darkGray5,
    border: 0,
    padding: {
      right: 30,
      left: 30,
      top: 6,
      bottom: 6,
    },
    '&:hover': {
      backgroundColor: Colors.darkGray3,
    },
    [`@media (min-width: ${Breakpoints.desktop}px)`]: {
      marginLeft: 20,
      color: Colors.darkGray2,
      backgroundColor: Colors.darkGray5,
    }
  },
  dot: {
    top: -8,
    right: -10
}
})
